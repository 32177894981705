<template>
  <!--
    Used to display the layout texts that are used in the videoPostprocessing themes.
  -->
  <Portlet
    :title="portletTitle"
    class="layoutTextList"
    icon="clone"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <ThemeElementTabs
        :theme-id="themeId"
      />

      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(layoutTextElements)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(layoutTextElements)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a text"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="previewTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <Preview
              :edit="false"
              :preview-only="true"
              :element="props.dataItem"
              style="width:5rem; float:left;margin-right: 1rem;overflow:hidden;"
              tag="rect"
            />
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <router-link
                :to="`/videopostprocessing/theme/${ themeId }/layouttextelement/${ props.dataItem.id }`"
                class="btn btn-primary btn-sm"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Edit</span>
              </router-link>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmRemoveModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
    </template>
    
    <div class="mt-4">
      <router-link
        :to="`/videopostprocessing/theme`"
        class="btn btn-sm btn-secondary float-left"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="caret-left"
        />{{ $t('back') }}
      </router-link>
      <button
        class="btn btn-sm btn-primary float-right"
        @click="addLayoutTextElement"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </div>

    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete layout text?"
      class="overflowHidden"
    >
      <p>{{ $t('layoutTextListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeLayoutTextElement()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <ThemeElementAdd
        :theme-id="themeId"
        element-type="LayoutTextElement"
        @reloadThemeElements="closeAndReloadData"
      />
    </Sidebar>
  </Portlet>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "LayoutTextList",
  components: {
    Preview: () => import('@/components/VideoPostprocessing/Preview.vue'),
    SweetModal,
    ThemeElementTabs: () => import('@/components/VideoPostprocessing/Theme/ThemeElementTabs.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    ThemeElementAdd: () => import('@/components/VideoPostprocessing/Theme/ThemeElementAdd.vue')
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    themeId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      theme: null,
      showSidebar: false,
      layoutTextElements: null,
      loading: true,
      indexToDelete: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'text',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "text",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          filterable: false,
          title: 'Preview',
          width: '90px',
          cell: 'previewTemplate'
        },
        {
          filterable: true,
          filter: 'text',
          title: 'Text',
          field: 'text',
          filterCell: "textFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '200px'
        }
      ]
    }
  },
  computed: {
    portletTitle () {
      if (this.theme == null) {
        return this.$t('theme');
      }
      if (this.theme.name == null) {
        return this.$t('theme');
      }
      return `${ this.$t('theme') }: ${ this.theme.name }`
    }
  },
  async created () {
    await this.getTheme();
    this.getLayoutTextElements();
  },
  methods: {
    //#region Api-calls
    addLayoutTextElement () {
      this.showSidebar = true;
    },
    closeAndReloadData () {
      this.showSidebar = false;    
      this.getLayoutTextElements();
      this.$emit("reloadAuditLogs");
    },
    close () {
      this.showSidebar = false;
    },
    reloadElements () {
      this.getLayoutTextElements();
      this.show();
    },
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    removeLayoutTextElement () {
      this.axios.delete('/VideoPostProcessing/DeleteLayoutTextElement?layoutTextElementId=' + this.indexToDelete)
        .then(() => {
          this.$emit("reloadAuditLogs");
          this.getLayoutTextElements();
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
        });
    },
    getLayoutTextElements () {
      this.axios.get('/VideoPostProcessing/GetAllLayoutTextElements?themeId=' + this.themeId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.layoutTextElements = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getTheme ()
    {
      await this.axios.get(`/VideoPostProcessing/GetTheme?themeId=${ this.themeId }`)
        .then((response) => {
          this.theme = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
    //#endregion
  }
}
</script>
<style>
.layoutTextList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>